import { isArray, isObject, mapValues, mapKeys, camelCase, omit, get } from 'lodash';
import { set } from 'lodash/fp';
import { SubmissionError } from 'redux-form';
import { useQuery as useQueryOrigin, useMutation as useMutationOrigin } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useNotify } from '../actions';

const camel = (value: any) =>
  isArray(value)
    ? value.map(camel)
    : isObject(value)
      ? mapValues(
          mapKeys(value, (_v: any, k: string) => camelCase(k)),
          (v: any) => camel(v)
        )
      : value;

export const getBaseError = (data: any): [any, any] => {
  if (isArray(data)) {
    let idx = 0;
    for (const v of data) {
      const [base, rest] = getBaseError(v);
      if (base) {
        return [base, set(`[${idx}]`, rest, data)];
      }
      idx++;
    }
    return [null, null];
  }
  if (!isObject(data)) {
    return [null, null];
  }
  for (const [key, value] of Object.entries(data)) {
    if (key === 'base') {
      return [value, omit(data, 'base')];
    } else {
      const [base, rest] = getBaseError(value);
      if (base) {
        return [base, set(key, rest, data)];
      }
    }
  }
  return [null, null];
};

export const useQuery = (query: DocumentNode, option: any) => {
  const ret = useQueryOrigin(query, option);
  if (ret.error) {
    throw ret.error;
  }
  return ret;
};

export const useMutation = (query: DocumentNode, option: any = {}) => {
  const notify = useNotify();
  const [mutation, value] = useMutationOrigin(query, option);

  const enhancedMutation = async (...args) => {
    try {
      const res = await mutation(...args);
      return res;
    } catch (err) {
      const [msg, submissionErrors] = parseErrorMessage(err);

      // TODO: Rollbar の設定と仕込む箇所を整理する
      // Rollbar.error(err);
      notify(msg, 'error');
      if (submissionErrors) {
        throw new SubmissionError(submissionErrors);
      }
      throw err;
    }
  };

  return { mutation: enhancedMutation, value };
};

const parseErrorMessage = (err: any): [string, any?] => {
  const defaultMsg = '予期せぬエラーが発生しました';
  const graphQLErrorMsg =
    get(err, 'graphQLErrors[0].extensions.problems[0].explanation') || get(err, 'graphQLErrors[0].message', defaultMsg);

  try {
    const errors = camel(JSON.parse(graphQLErrorMsg));
    const [base, rest] = getBaseError(errors);
    return base ? [base, rest] : [graphQLErrorMsg, errors];
  } catch {
    const networkErrorMsg = get(err, 'networkError.result.error') || err.message || defaultMsg;
    return [networkErrorMsg];
  }
};
