import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import useReactRouter from 'use-react-router';
import { useSession } from '../SessionProvider';

const defaultLimit = '100';
const limitKey = (pathname, user) => `${pathname.replace(/\//g, '')}#${user.id}__disp_emp_limit`;

export const getSavedDisplayEmployeeLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user));
    if (!isEmpty(limit)) {
      return limit;
    }
  }
  const limit = Cookies.get(limitKey(pathname, user));
  return isEmpty(limit) ? defaultLimit : limit;
};

export const saveDisplayEmployeeLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit);
  } else {
    Cookies.set(limitKey(pathname, user), limit);
  }
};

export const useDisplayEmployeeLimit = () => {
  const {
    location: { pathname }
  } = useReactRouter();
  const { user } = useSession();
  const limit = getSavedDisplayEmployeeLimit(pathname, user);
  return [limit, limit => saveDisplayEmployeeLimit(pathname, user, limit)];
};
