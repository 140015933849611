import React, { useState } from 'react';
import { LoadingPage } from '../components';
import { reduxForm } from '../components/FormName';
import { useNotify } from '../actions';
import { Section, TextField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { checkToken, setPassword } from '../api';
import { password } from '../validators';
import { isErrorWithMessage } from '../components';

const PasswordForm = ({ handleSubmit, pristine, loading }) => (
  <form>
    <Section>
      <TextField name="password" label="パスワード" type="password" validate={password} required={true} />
      <TextField
        name="password_confirmation"
        label="パスワード(確認用)"
        type="password"
        validate={password}
        required={true}
      />
      <div className="u-ta-c u-mt30">
        <Button onClick={handleSubmit} primary disabled={pristine || loading}>
          登録する
        </Button>
      </div>
    </Section>
  </form>
);

const ConnectedForm = reduxForm({
  form: 'passwordForm'
})(PasswordForm);

export const InvitationConfirmation = ({ location: { search }, history }) => {
  const [status, setStatus] = useState('loading');
  const [setting, setSetting] = useState(false);
  const { token } = queryString.parse(search);
  const notify = useNotify();
  const onSubmit = async (token, values) => {
    setSetting(true);
    if (values.password && values.password_confirmation && values.password !== values.password_confirmation) {
      notify('パスワード(確認用)が一致しません', 'error');
    } else {
      try {
        await setPassword({
          token: token,
          password: values.password,
          password_confirmation: values.password_confirmation
        });
        history.push('/dashboard');
        window.location.reload();
        notify('アカウントが登録されました', 'success');
      } catch (err) {
        let errorMessage = '予期せぬエラーが発生しました';
        if (isErrorWithMessage(err, '400')) {
          errorMessage = '不正なパスワードです';
        }
        notify(errorMessage, 'error');
      }
    }
    setSetting(false);
  };
  const check = async token => {
    try {
      if (status === 'loading') {
        await checkToken(token);
        setStatus('complete');
      }
    } catch (err) {
      let errType = 'error';
      if (isErrorWithMessage(err, '401')) {
        errType = 'not_valid';
      }
      setStatus(errType);
    }
  };
  check(token);
  if (status === 'loading') {
    return <LoadingPage />;
  }
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">招待承諾画面</h1>
      </div>
      {status === 'complete' ? (
        <ConnectedForm
          onSubmit={values => {
            onSubmit(token, values);
          }}
          loading={setting}
        />
      ) : (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            {(status === 'error' && <p>エラーが発生しました。もう一度お試しください。</p>) ||
              (status === 'not_valid' && (
                <p>
                  このURLは登録が完了しているか、有効期限が過ぎています。
                  <br />
                  <Link to="/login" className="u-txt-link">
                    ログインはこちらから
                  </Link>
                </p>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
