import React from 'react';
import { Modal, useQuery, useMutation, LoadingPage } from '../components';
import Button from 'jbc-front/components/Button';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import styles from './Mails.scss';
import { Copy, Delete, Edit } from 'jbc-front/components/icons';
import { find } from 'lodash';
import { useNotify } from '../actions';
import useReactRouter from 'use-react-router';

const MAILS = gql`
  query mails {
    client {
      id
      mails {
        id
        name
        mailType
        type
        isDefault
      }
    }
  }
`;

const DELETE_MAIL = gql`
  mutation delete_mail($input: DeleteMailInput!) {
    deleteMail(input: $input) {
      client {
        id
        mails {
          id
          name
          mailType
          type
        }
      }
    }
  }
`;

const mailTypes = [
  { id: 1, label: '依頼メール' },
  { id: 2, label: '勧奨メール' },
  { id: 3, label: '取消メール' }
];

export const Mails = () => {
  const notify = useNotify();
  const { history } = useReactRouter();
  const { data, loading } = useQuery(MAILS, { fetchPolicy: 'network-only' });
  const deleteMail = useMutation(DELETE_MAIL);
  const mails: any[] = data?.client?.mails || [];
  const deleteFunc = async mailId => {
    await deleteMail.mutation({
      variables: {
        input: {
          mailId: mailId
        }
      }
    });
    notify('削除しました', 'success');
  };
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">メールカスタマイズ</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.main}>
            <div className={styles.createMail}>
              <div className="l-breadcrumb">
                <Link to="/settings" className="l-breadcrumb-link">
                  設定
                </Link>
                <span className="l-breadcrumb-here">メールカスタマイズ</span>
              </div>
              <div className={styles.alignRight}>
                <Button
                  className={styles.createMailButton}
                  onClick={() => {
                    history.push('/settings/mails/create');
                  }}
                  primary>
                  メール作成
                </Button>
              </div>
            </div>
            {loading ? (
              <LoadingPage />
            ) : !data?.client ? (
              <div className="l-wrap-xs u-pt100">
                <div className="l-box-message">
                  <p className="m-title-sub u-pt0 u-ta-c">メールを取得できませんでした。</p>
                </div>
              </div>
            ) : (
              <div className="l-overflow-scroll">
                <table className={styles.table}>
                  <thead>
                    <tr className={styles.header}>
                      <th className={styles.titleColumn}>メール名</th>
                      <th className={styles.typeColumn}>メール種別</th>
                      <th className={styles.buttons} colSpan={3}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {mails.length > 0 && (
                      <>
                        {mails.map(mail => (
                          <tr key={mail.id} className={styles.row}>
                            <td>{mail.name}</td>
                            <td>{find(mailTypes, { id: mail.mailType })?.label}</td>
                            <td className={styles.copyColumn}>
                              <Link className={styles.copy} to={`/settings/mails/create?origin=${mail.id}`}>
                                <div className={styles.noteWrap}>
                                  <Copy size={20} className={styles.icon} />
                                  <div className={styles.note}>コピーして新規作成</div>
                                </div>
                              </Link>
                            </td>
                            <td className={styles.editColumn}>
                              <Link className={styles.copy} to={`/settings/mails/edit/${mail.id}`}>
                                <div className={styles.noteWrap}>
                                  <Edit size={20} className={styles.icon} />
                                  <div className={styles.note}>メールを編集</div>
                                </div>
                              </Link>
                            </td>
                            {!mail.isDefault ? (
                              <td className={styles.deleteColumn}>
                                <Modal>
                                  {({ hideModal, showModal, isOpen }) => {
                                    return (
                                      <>
                                        <div
                                          onClick={() => {
                                            showModal();
                                          }}>
                                          <div className={styles.noteWrap}>
                                            <Delete size={20} className={styles.icon} />
                                            <div className={styles.note}>メールを削除</div>
                                          </div>
                                        </div>
                                        {isOpen && (
                                          <Modal.Modal>
                                            <div className={styles.modal}>
                                              <Modal.Header hideModal={hideModal}>削除の確認</Modal.Header>
                                              <Modal.Body>
                                                <div>削除されたメールは復元することができません。</div>
                                                <div className={styles.confirm}>本当に削除しますか</div>
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <div className={styles.modalFooter}>
                                                  <Modal.Buttons>
                                                    <Button className={styles.cancelButton} onClick={hideModal}>
                                                      いいえ
                                                    </Button>
                                                    <Button
                                                      className={styles.deleteButton}
                                                      primary
                                                      onClick={() => deleteFunc(mail.id)}>
                                                      はい
                                                    </Button>
                                                  </Modal.Buttons>
                                                </div>
                                              </Modal.Footer>
                                            </div>
                                          </Modal.Modal>
                                        )}
                                      </>
                                    );
                                  }}
                                </Modal>
                              </td>
                            ) : (
                              <td className={styles.deleteColumn}></td>
                            )}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
