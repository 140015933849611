import React, { useState } from 'react';
import { Modal, useMutation, reduxForm, useQuery, LoadingPage, useAsyncTask, ASYNC_TASK_FRAGMENT } from '../components';
import { gql } from '@apollo/client';
import styles from './BulkUpdate.scss';
import { useNotify } from '../actions';
import { filter, some } from 'lodash';
import Button from 'jbc-front/components/Button';
import { SelectField } from 'jbc-front/components/Form';
import useReactRouter from 'use-react-router';
import moment from 'moment';

const REQUEST_OPTION = gql`
  query request_option($mailType: Int!) {
    client {
      id
      mails(mailType: $mailType) {
        id
        name
        title
        content
        updatedAt
      }
      asyncTask {
        ...AsyncTaskFields
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const UPDATE_REQUEST = gql`
  mutation addEmployeeSurvey($input: AddEmployeeSurveyInput!) {
    addEmployeeSurvey(input: $input) {
      client {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const BulkUpdate = ({ selected, selectedSurvey }) => {
  const { history } = useReactRouter();
  const requestOptions = useQuery(REQUEST_OPTION, {
    variables: { mailType: 1 },
    fetchPolicy: 'no-cache'
  });

  if (requestOptions.loading) {
    return <LoadingPage />;
  }

  const taskRunning = ['waiting', 'in_progress'].includes(requestOptions.data.client.asyncTask?.status);
  const mails = requestOptions.data.client.mails;

  const mailOptions = mails.map(mail => {
    return {
      label: mail.name,
      value: mail.id
    };
  });

  return (
    <Modal>
      {({ hideModal, showModal, isOpen }) => {
        const { taskRunningProps } = useAsyncTask();
        const [selectedMail, setSelectedMail] = useState(mailOptions[0].value);
        const {
          mutation,
          value: { loading }
        } = useMutation(UPDATE_REQUEST);
        const notify = useNotify();
        const sendForm = async () => {
          if (selectedMail) {
            await mutation({
              variables: {
                input: {
                  employees: filter(selected),
                  implementationSurveyId: parseInt(selectedSurvey.id, 10),
                  mailId: parseInt(selectedMail, 10)
                }
              }
            });
            hideModal();
            history.push(`/personal_results/${selectedSurvey.id}`);
          } else {
            notify('メール内容を選択してください', 'error');
          }
        };
        return (
          <>
            <div className={styles.sendFormArea}>
              <Button
                primary
                className={styles.showModal}
                onClick={() => showModal()}
                disabled={loading || !some(selected)}>
                フォームを送信する
              </Button>
              {Object.keys(selected).length > 0 && (
                <div className={styles.count}>選択中の従業員数：{filter(selected).length} 人</div>
              )}
            </div>
            {isOpen && (
              <form>
                <Modal.Modal>
                  <div className={styles.modal}>
                    <Modal.Header hideModal={hideModal}>フォームを送信する</Modal.Header>
                    <Modal.Body>
                      <div className={styles.modalBody}>
                        <div className={styles.sendCount}>
                          送信人数：{filter(selected).length} 人<br />
                        </div>
                        <div className={styles.surveyItem}>
                          <div>実施期間</div>
                          <div>{moment(selectedSurvey.surveyEndDate).format('YYYY年MM月DD日')}まで</div>
                        </div>
                        <div className={styles.surveyItem}>
                          <div>調査フォーム</div>
                          <div>{selectedSurvey.surveyForm.title}</div>
                        </div>
                        <div className={styles.mailField}>
                          <SelectField
                            input={{
                              value: selectedMail,
                              onChange: value => {
                                setSelectedMail(value);
                              }
                            }}
                            label="メール内容"
                            name="Mail"
                            simpleValue
                            required
                            clearable={false}
                            options={mailOptions}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className={styles.modalFooter}>
                        <Modal.Buttons>
                          <Button className={styles.cancelButton} onClick={hideModal}>
                            キャンセル
                          </Button>
                          <Button
                            className={styles.sendButton}
                            primary
                            {...taskRunningProps}
                            onClick={sendForm}
                            disabledReason={taskRunning ? 'ストレスチェック送信中のため送信できません' : null}
                            disabled={!selectedMail || loading || taskRunning}>
                            送信
                          </Button>
                        </Modal.Buttons>
                      </div>
                    </Modal.Footer>
                  </div>
                </Modal.Modal>
              </form>
            )}
          </>
        );
      }}
    </Modal>
  );
};

export const BulkUpdateForm = reduxForm({
  form: 'BulkUpdateForm',
  enableReinitialize: true
})(BulkUpdate);
