import React, { useContext, useCallback } from 'react';
import { formValueSelector, reduxForm as _reduxForm, autofill } from 'redux-form';
import { compose } from 'lodash/fp';
import { useSelector, useDispatch } from 'react-redux';

type ContextType = {
  formName: string | null;
  selector: (state: any, ...args: any[]) => null;
  autofill: (...args: any[]) => void;
};

const Context = React.createContext<ContextType>({
  formName: null,
  selector: () => null,
  autofill: () => {}
});

export const useFormName = () => useContext(Context);

export const useFormValue = (...args) => {
  const { selector } = useFormName();
  return useSelector(state => selector(state, ...args));
};

export const useAutofill = () => {
  const { autofill, selector } = useFormName();
  const dispatch = useDispatch();
  const wrapedAutoFill = useCallback((...args) => dispatch(autofill(...args)), [autofill, dispatch]);
  const withSelector = useCallback(
    f => {
      dispatch((_, getState) => {
        const state = getState();
        f((...args) => selector(state, ...args));
      });
    },
    [dispatch, selector]
  );
  return {
    autofill: wrapedAutoFill,
    withSelector
  };
};

export const FormNameProvider = ({ children, formName }) => {
  const selector = useCallback(formValueSelector(formName), [formName]);
  const wrapedAutoFill = useCallback(autofill.bind(null, formName), [formName]);
  return <Context.Provider value={{ formName, selector, autofill: wrapedAutoFill }}>{children}</Context.Provider>;
};

export const reduxForm = opts =>
  compose(_reduxForm(opts), Child => props => (
    <FormNameProvider formName={opts.form}>
      <Child {...props} />
    </FormNameProvider>
  ));
