import React from 'react';
import { filter, find, map, sum } from 'lodash';
import { Radar } from 'react-chartjs-2';
import Button from 'jbc-front/components/Button';
import styles from './ResultDetail.scss';
import { NotableMark } from 'jbc-front/components/icons';
import { TopComment, BottomComment } from './Comments';

const displaySentence = {
  submitResult: {
    japanese: '事業者に結果を提供する',
    english: 'Submit result'
  },
  alreadySubmitted: {
    japanese: '事業者に結果を提供済み',
    english: 'Results have been submitted'
  },
  notableZone: {
    japanese: '要注意ゾーン',
    english: 'Notable Zone'
  },
  note: {
    japanese: '（注）このグラフは、中心に近いほどストレスが高いことを示しています。',
    english: '*Note: The closer to the center of this graph, the higher the stress levels are.'
  },
  categories: {
    japanese: '項目',
    english: 'Categories'
  },
  points: {
    japanese: '評価点',
    english: 'Points'
  },
  shortPoints: {
    japanese: '点',
    english: ''
  },
  total: {
    japanese: '合計',
    english: 'Total'
  },
  feedback: {
    japanese: '講評',
    english: 'Feedback'
  }
};

export const ResultDetail = ({ employeeSurvey, showModal, language }) => {
  const categoryValues: any[] = employeeSurvey.implementationSurvey.surveyForm.surveyFormQuestionDisplayCategories.map(
    category => {
      const values: any = filter(employeeSurvey.employeeSurveyCalculatedValues, {
        categoryNumber: category.categoryNumber
      })[0].employeeSurveyCalculatedChildValues;
      const labels: any = category.surveyFormQuestionDisplayChildCategories.map(childCategory => {
        return find(childCategory.childCategoryTitles, { language: language }).title.split('\n');
      });
      if (!values.length) return null;
      return {
        categoryNumber: category.categoryNumber,
        title: find(category.categoryTitles, { language: language }).title,
        values: values,
        labels: labels
      };
    }
  );

  const results = categoryValues.map(value => {
    let labels = value.labels;
    let levels = map(value.values, v => v.level);
    let alertLevels = new Array<number>(value.values.length).fill(1);
    if (value.values.length === 2) {
      labels = labels.concat(['', '']);
      levels = levels.concat([0, 0]);
      alertLevels = alertLevels.concat([0, 0]);
    }
    return {
      categoryNumber: value.childCategoryNumber,
      title: value.title,
      point: sum(map(value.values, v => v.value)),
      options: {
        legend: {
          display: false
        },
        scale: {
          gridLines: {
            circular: true,
            drawTicks: false
          },
          ticks: {
            beginAtZero: true,
            max: 5,
            maxTicksLimit: 1,
            display: false
          }
        }
      },
      labels: labels,
      datasets: [
        {
          backgroundColor: 'rgba(255, 234, 111, 0.6)', // #ffea6f
          pointRadius: 0,
          borderWidth: 1,
          data: levels
        },
        {
          backgroundColor: 'rgba(255, 197, 98, 0.6)', // #ffc562
          pointRadius: 0,
          borderWidth: 1,
          data: alertLevels
        }
      ]
    };
  });
  return (
    <>
      <div className={styles.title}>
        {employeeSurvey.employee.lastName} {employeeSurvey.employee.firstName}
        {showModal &&
          (employeeSurvey.isProvided ? (
            <div className={styles.provideButton}>{displaySentence.alreadySubmitted[language]}</div>
          ) : (
            <Button
              className={styles.provideButton}
              onClick={() => {
                showModal();
              }}
              primary>
              {displaySentence.submitResult[language]}
            </Button>
          ))}
      </div>
      <TopComment
        values={categoryValues}
        formId={parseInt(employeeSurvey.implementationSurvey.surveyForm.id)}
        language={language}
      />
      <div className={styles.chart}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr className={styles.row}>
              {results.map(d => (
                <td className={styles.radar} key={d.title}>
                  <div className={styles.chartTitle}>{d.title}</div>
                  <Radar data={d} options={d.options} height={210} />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.description}>
        <div className={styles.notableDescription}>
          <div className={styles.rectangle} />
          <div className={styles.notableZone}>{displaySentence.notableZone[language]}</div>
        </div>
        <div className={styles.chartInfo}>{displaySentence.note[language]}</div>
      </div>
      <div className={styles.result}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.itemColumn}>{displaySentence.categories[language]}</th>
              <th className={styles.scoreColumn}>{displaySentence.points[language]}</th>
            </tr>
          </thead>
          <tbody>
            {results.map(d => {
              return (
                <tr key={d.title} className={styles.tr}>
                  <th className={styles.th} colSpan={2}>
                    <div className={styles.item}>{d.title}</div>
                    <div className={styles.score}>
                      {d.point}
                      {displaySentence.shortPoints[language]}
                    </div>
                  </th>
                </tr>
              );
            })}
            <tr className={styles.tr}>
              <th className={styles.th} colSpan={2}>
                <div className={styles.item}>{displaySentence.total[language]}</div>
                <div className={styles.score}>
                  {results.length > 0
                    ? map(results, 'point').reduce((accumulator, current) => accumulator + current)
                    : 0}
                  {displaySentence.shortPoints[language]}
                </div>
              </th>
            </tr>
            <tr className={styles.tr}>
              <th className={styles.th} colSpan={2}>
                {employeeSurvey.implementationSurvey.surveyForm.hasNotableCheck && employeeSurvey.isNotable && (
                  <div className={styles.notableCheck}>
                    <div className={styles.notableMark}>
                      <NotableMark size={20} className={styles.notableCheckItem} />
                    </div>
                    <div className={styles.notableSentence}>
                      <h4>
                        {
                          find(
                            employeeSurvey.implementationSurvey.surveyForm.surveyFormNotableCheck.notableCheckTitles,
                            { language: language }
                          ).description
                        }
                      </h4>
                    </div>
                  </div>
                )}
                <div className={styles.comments}>
                  <h3>{displaySentence.feedback[language]}</h3>
                  <BottomComment
                    values={categoryValues}
                    comments={employeeSurvey.employeeSurveyComments}
                    formId={parseInt(employeeSurvey.implementationSurvey.surveyForm.id)}
                    language={language}
                  />
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
