import React from 'react';
import { useQuery, useMutation, MailForm } from '../components';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import styles from './CreateMail.scss';
import { useNotify } from '../actions';
import useReactRouter from 'use-react-router';
import queryString from 'query-string';

const GET_ORIGIN = gql`
  query getOrigin($mailId: Int!) {
    mail(mailId: $mailId) {
      id
      name
      mailType
      title
      content
    }
  }
`;

const CREATE_MAIL = gql`
  mutation create_mail($input: CreateMailInput!) {
    createMail(input: $input) {
      client {
        id
        mails {
          id
          name
        }
      }
    }
  }
`;

export const CreateMail = () => {
  const notify = useNotify();
  const { history } = useReactRouter();
  const { location } = useReactRouter();
  const { origin, type } = queryString.parse(location.search);
  let initialValues: any = null;
  if (typeof origin === 'string') {
    let mailId = parseInt(origin);
    if (type === 'default') {
      mailId *= -1;
    }
    const { data } = useQuery(GET_ORIGIN, {
      variables: { mailId: mailId },
      fetchPolicy: 'no-cache'
    });
    if (data?.mail) {
      initialValues = { ...data.mail, name: `${data.mail.name}のコピー` };
    }
  }
  const createMailFunc = useMutation(CREATE_MAIL);
  const createMail = async ({ name, mailType, title, content }) => {
    if (!name) {
      notify('管理ラベルを入力してください', 'error');
    } else if (!mailType) {
      notify('メール種別を入力してください', 'error');
    } else if (!title) {
      notify('件名を入力してください', 'error');
    } else if (!content) {
      notify('メール本文を入力してください', 'error');
    } else {
      await createMailFunc.mutation({
        variables: {
          input: {
            name: name,
            mailType: mailType,
            title: title,
            content: content
          }
        }
      });
      notify('メールを作成しました', 'success');
      history.push('/settings/mails');
    }
  };
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">メールカスタマイズ</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.main}>
            <div className="l-breadcrumb">
              <Link to="/settings" className="l-breadcrumb-link">
                設定
              </Link>
              <span className="l-breadcrumb-here">
                <Link to="/settings/mails" className="l-breadcrumb-link">
                  メールカスタマイズ
                </Link>
              </span>
              <span className="l-breadcrumb-here">新規作成</span>
            </div>
            <MailForm initialValues={initialValues} onSubmit={createMail} />
          </div>
        </div>
      </div>
    </div>
  );
};
