import React, { useState } from 'react';
import { useQuery, useSession, LoadingPage, getTitle } from '../components';
import { convertQueryToForm, getCurrentQueryFromLocation, convertFormToGraphQl } from '../personal_results/utils';
import { Survey, initialSurvey } from '../entity';
import { gql } from '@apollo/client';
import styles from './GroupResult.scss';
import Select from 'react-select';
import useReactRouter from 'use-react-router';
import { find, some, orderBy, sumBy } from 'lodash';

const RED_ALERT_BORDER = 1.5;
const YELLOW_ALERT_BORDER = 2;
const EMPLOYEE_SURVEYS_COUNT_MIN = 5;

const SURVEYS = gql`
  query surveys {
    client {
      id
      implementationSurveys {
        id
        createdAt
        surveyEndDate
        surveyForm {
          id
          title
        }
      }
    }
  }
`;

const GROUP_RESULTS = gql`
  query groupResults($id: ID!) {
    client {
      implementationSurvey(id: $id) {
        id
        answeredCount
        surveyForm {
          id
          title
          surveyFormQuestionDisplayCategories {
            id
            categoryNumber
            categoryTitles {
              id
              language
              shortTitle
            }
            surveyFormQuestionDisplayChildCategories {
              id
              categoryNumber
              childCategoryTitles {
                id
                language
                title
              }
            }
          }
        }
        groupResults {
          angle
          groupValues {
            label
            count
            groupCategoryValues {
              categoryNumber
              groupChildCategoryValues {
                childCategoryNumber
                average
              }
            }
          }
        }
      }
    }
  }
`;

const angles = [
  { id: 'gender', name: '性別' },
  { id: 'office', name: '適用事業所' },
  { id: 'employment_type', name: '雇用形態' },
  { id: 'occupation', name: '職種' },
  { id: 'group', name: 'グループ' },
  { id: 'position', name: '役職' }
];

interface ResultsProps {
  selectedSurvey: Survey;
  surveys: Survey[];
}

const Results: React.FC<ResultsProps> = ({ selectedSurvey, surveys }) => {
  const language = 'japanese';
  const { user } = useSession();
  const {
    history,
    location: { search, pathname }
  } = useReactRouter();
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, user));
  const variables = { id: selectedSurvey.id, ...convertFormToGraphQl(formValues) };
  const groupResults = useQuery(GROUP_RESULTS, { variables, fetchPolicy: 'no-cache' });
  const [angle, setAngle] = useState('gender');
  const surveyOptions = surveys.map(survey => ({
    label: getTitle(survey),
    value: survey
  }));
  const list = (!groupResults.loading && groupResults.data?.client.implementationSurvey.groupResults) || [];
  const answeredCount = (!groupResults.loading && groupResults.data?.client.implementationSurvey.answeredCount) || 0;
  const summary = find(list, { angle: angle });
  const survey = (!groupResults.loading && groupResults.data?.client.implementationSurvey) || undefined;
  const withAlert = some(summary?.groupValues || [], value =>
    some(value.groupCategoryValues, categoryValue =>
      some(
        categoryValue.groupChildCategoryValues,
        childCategoryValue => childCategoryValue.average <= YELLOW_ALERT_BORDER
      )
    )
  );
  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">集団分析</h1>
      </div>
      <div className={styles.contentsWrap}>
        <div className="l-wrap-xl">
          <Select
            name="SurveyForm"
            className={styles.select}
            simpleValue
            required
            clearable={false}
            value={{ label: getTitle(selectedSurvey), value: selectedSurvey }}
            options={surveyOptions}
            noResultsText={'実施済みのストレスチェックがありません'}
            onChange={(selected: any) => {
              history.push(`/group_result/${selected?.id}`);
            }}
          />
          <div className={styles.resultField}>
            {groupResults.loading ? (
              <LoadingPage />
            ) : answeredCount >= EMPLOYEE_SURVEYS_COUNT_MIN ? (
              <>
                <div className={styles.tags}>
                  {list.map(item => {
                    if (item.groupValues.length >= 3) {
                      return (
                        <div
                          key={item.angle}
                          className={item.angle === angle ? styles.selectedTag : styles.defaultTag}
                          onClick={() => {
                            setAngle(item.angle);
                          }}>
                          {find(angles, { id: item.angle })?.name}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className={styles.resultTableWrap}>
                  <div className="l-overflow-scroll">
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th className={styles.labelColumn} />
                          {survey ? (
                            survey.surveyForm.surveyFormQuestionDisplayCategories.map(category => (
                              <th
                                key={category.id}
                                className={styles.statusColumn}
                                colSpan={category.surveyFormQuestionDisplayChildCategories.length}>
                                {find(category.categoryTitles, { language: language }).shortTitle}
                              </th>
                            ))
                          ) : (
                            <th />
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {summary.groupValues && summary.groupValues.length > 0 ? (
                          <>
                            <tr className={styles.childCategoryTitleRow}>
                              <td />
                              {survey ? (
                                survey.surveyForm.surveyFormQuestionDisplayCategories.map(category =>
                                  category.surveyFormQuestionDisplayChildCategories.map((childCategory, idx) => {
                                    let addedClass = ' ';
                                    if (idx === 0) {
                                      addedClass += `${styles.withLeftBorder}`;
                                    }
                                    return (
                                      <td
                                        key={`${category.id}-${childCategory.id}-${idx}`}
                                        className={styles.childCategoryTitleCol + addedClass}>
                                        <span className={styles.vertical}>
                                          {find(childCategory.childCategoryTitles, {
                                            language: language
                                          }).title.replace('\n', '')}
                                        </span>
                                      </td>
                                    );
                                  })
                                )
                              ) : (
                                <td />
                              )}
                            </tr>
                            {summary.groupValues.length > 0 &&
                              summary.groupValues.map(item => (
                                <tr key={`row-${item.label}`} className={styles.row}>
                                  <td className={styles.label}>
                                    {item.label}
                                    <br />
                                    {`（${item.count}人）`}
                                  </td>
                                  {item.count >= EMPLOYEE_SURVEYS_COUNT_MIN ? (
                                    <>
                                      {orderBy(item.groupCategoryValues, 'categoryNumber').map(value => (
                                        <React.Fragment key={`category-${value.categoryNumber}`}>
                                          {orderBy(value.groupChildCategoryValues, 'categoryNumber').map(
                                            (childValue, idx) => {
                                              let addedClass = '';
                                              if (idx === 0) {
                                                addedClass += ` ${styles.withLeftBorder}`;
                                              }
                                              if (childValue.average <= RED_ALERT_BORDER) {
                                                addedClass += ` ${styles.withRedAlert}`;
                                              } else if (childValue.average <= YELLOW_ALERT_BORDER) {
                                                addedClass += ` ${styles.withYellowAlert}`;
                                              }
                                              return (
                                                <td
                                                  key={`td-${value.categoryNumber}-${childValue.childCategoryNumber}-${idx}`}
                                                  className={styles.average + addedClass}>
                                                  {childValue.average}
                                                </td>
                                              );
                                            }
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  ) : (
                                    <td
                                      key={`insufficient-${item.label}`}
                                      colSpan={sumBy(
                                        survey.surveyForm.surveyFormQuestionDisplayCategories,
                                        'surveyFormQuestionDisplayChildCategories.length'
                                      )}>
                                      {`対象人数が${EMPLOYEE_SURVEYS_COUNT_MIN}名未満のため表示できません`}
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </>
                        ) : (
                          <tr className={styles.row}>
                            <td colSpan={survey.surveyForm.surveyFormQuestionDisplayCategories.length + 3}>
                              回答済みの従業員がいません
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={styles.descriptionField}>
                  <div className={styles.description}>
                    <div className={styles.yellowRectangle} />
                    <div className={styles.comment}>ストレスの値がやや高い項目</div>
                  </div>
                  <div className={styles.description}>
                    <div className={styles.redRectangle} />
                    <div className={styles.comment}>ストレスの値が高い項目</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.resultTableWrap}>
                  <div className="l-overflow-scroll">
                    <table className={styles.notAnsweredTable}>
                      <thead>
                        <tr>
                          <th className={styles.labelColumn} />
                          {survey ? (
                            survey.surveyForm.surveyFormQuestionDisplayCategories.map(category => (
                              <th key={category.id} className={styles.statusColumn}>
                                {find(category.categoryTitles, { language: language }).shortTitle}
                              </th>
                            ))
                          ) : (
                            <th />
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={styles.row}>
                          <td className={styles.label}>
                            全体
                            <br />
                            {`（${answeredCount}人）`}
                          </td>
                          <td colSpan={survey.surveyForm.surveyFormQuestionDisplayCategories.length}>
                            {`回答者が${EMPLOYEE_SURVEYS_COUNT_MIN}名未満のため表示できません`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
          {summary?.groupValues &&
            summary.groupValues.length > 0 &&
            answeredCount >= EMPLOYEE_SURVEYS_COUNT_MIN &&
            (withAlert ? (
              <div className={styles.alertField}>
                <div className={styles.alertMain}>下記について注意・改善しましょう</div>
                <div className={styles.alertMessageField}>
                  {summary.groupValues.map(item =>
                    orderBy(item.groupCategoryValues, 'categoryNumber').map((value, idx1) =>
                      orderBy(value.groupChildCategoryValues, 'categoryNumber').map((childValue, idx2) => {
                        const category = (survey?.surveyForm?.surveyFormQuestionDisplayCategories || [])[idx1];
                        const childCategory = (category?.surveyFormQuestionDisplayChildCategories || [])[idx2];
                        const title =
                          find(childCategory?.childCategoryTitles || [], { language: language })?.title?.replace(
                            '\n',
                            ''
                          ) || '';
                        return (
                          childValue.average <= YELLOW_ALERT_BORDER &&
                          (childValue.average <= RED_ALERT_BORDER ? (
                            <div className={styles.alertMessage}>
                              {`【${item.label}】の【${title}】によるストレスの値が高い状態です`}
                            </div>
                          ) : (
                            <div className={styles.alertMessage}>
                              {`【${item.label}】の【${title}】によるストレスの値がやや高い状態です`}
                            </div>
                          ))
                        );
                      })
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.alertField}>
                <div className={styles.noAlert}>高ストレス対象は見受けられませんでした。組織は健全な状態です。</div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export const GroupResult = ({
  match: {
    params: { surveyId }
  }
}) => {
  const { data: fetchedSurveys, loading: loadingSurveys } = useQuery(SURVEYS, { fetchPolicy: 'no-cache' });
  if (loadingSurveys) {
    return <LoadingPage />;
  }

  const surveys = fetchedSurveys?.client?.implementationSurveys || [];
  const selectedSurvey = find(surveys, { id: surveyId }) || surveys[0] || initialSurvey;

  if (surveys.length === 0) {
    return (
      <div className="l-wrap-xs u-pt100">
        <div className="l-box-message">
          <p>ストレスチェックがありません</p>
        </div>
      </div>
    );
  } else {
    return <Results selectedSurvey={selectedSurvey} surveys={surveys} />;
  }
};
