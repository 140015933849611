import { combineReducers } from 'redux';
import { reducer as form, FormStateMap } from 'redux-form';
import { reducer as formErrors } from 'jbc-front/components/FormErrors';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

export interface RootState {
  router: RouterState;
  form: FormStateMap;
  formErrors: any;
}

const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    form,
    formErrors
  });

export default rootReducer;
