import React, { useState } from 'react';
import { Modal, useMutation, reduxForm, useQuery, LoadingPage, useAsyncTask, ASYNC_TASK_FRAGMENT } from '../components';
import { gql } from '@apollo/client';
import styles from './BulkRequest.scss';
import { useNotify } from '../actions';
import { filter, some } from 'lodash';
import Button from 'jbc-front/components/Button';
import { DateField, SelectField } from 'jbc-front/components/Form';
import { futureDate } from '../validators';
import useReactRouter from 'use-react-router';
import moment from 'moment';

const REQUEST_OPTION = gql`
  query request_option($mailType: Int!) {
    client {
      id
      surveyForms {
        id
        title
      }
      mails(mailType: $mailType) {
        id
        name
        title
        content
        updatedAt
      }
      asyncTask {
        ...AsyncTaskFields
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const SEND_REQUEST = gql`
  mutation send_request($input: SendRequestInput!) {
    sendRequest(input: $input) {
      client {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const BulkRequest = ({ selected }) => {
  const { history } = useReactRouter();
  const requestOptions = useQuery(REQUEST_OPTION, {
    variables: { mailType: 1 },
    fetchPolicy: 'no-cache'
  });
  if (requestOptions.loading) {
    return <LoadingPage />;
  }
  const taskRunning = ['waiting', 'in_progress'].includes(requestOptions.data.client.asyncTask?.status);
  const mails = requestOptions.data.client.mails;
  const surveyForms = requestOptions.data.client.surveyForms;
  const mailOptions = mails.map(mail => {
    return {
      label: mail.name,
      value: mail.id
    };
  });
  const formOptions = surveyForms.map(form => {
    return {
      label: form.title,
      value: form.id
    };
  });

  return (
    <Modal>
      {({ hideModal, showModal, isOpen }) => {
        const { taskRunningProps } = useAsyncTask();
        const [surveyEndDate, setSurveyEndDate] = useState(null);
        const [selectedMail, setSelectedMail] = useState(mailOptions[0].value);
        const [selectedSurveyForm, setSelectedSurveyForm] = useState(formOptions[0].value);
        const {
          mutation,
          value: { loading }
        } = useMutation(SEND_REQUEST);
        const notify = useNotify();
        const isFutureDate = date => {
          return date && moment(date).endOf('days').isAfter(moment());
        };
        const sendForm = async () => {
          if (isFutureDate(surveyEndDate) && selectedMail && selectedSurveyForm) {
            await mutation({
              variables: {
                input: {
                  employees: filter(selected),
                  formId: parseInt(selectedSurveyForm, 10),
                  mailId: parseInt(selectedMail, 10),
                  surveyEndDate: surveyEndDate
                }
              }
            });
            hideModal();
            history.push('/dashboard');
          } else if (!isFutureDate(surveyEndDate)) {
            notify('実施期限には未来の日付を入れてください', 'error');
          } else {
            notify('調査フォームとメール内容を選択してください', 'error');
          }
        };
        return (
          <>
            <div className={styles.sendFormArea}>
              <Button
                primary
                className={styles.showModal}
                onClick={() => showModal()}
                disabled={loading || !some(selected)}>
                フォームを送信する
              </Button>
              {Object.keys(selected).length > 0 && (
                <div className={styles.count}>選択中の従業員数：{filter(selected).length}人</div>
              )}
            </div>

            {isOpen && (
              <form>
                <Modal.Modal>
                  <div className={styles.modal}>
                    <Modal.Header hideModal={hideModal}>フォームを送信する</Modal.Header>
                    <Modal.Body>
                      <div className={styles.modalBody}>
                        <div className={styles.sendCount}>
                          送信人数：{filter(selected).length}人<br />
                        </div>
                        <div className={styles.dateField}>
                          <div className={styles.selectDate}>
                            <DateField
                              name="surveyEndDate"
                              label="実施期間"
                              onChange={(_, value) => setSurveyEndDate(value)}
                              validate={futureDate}
                              required
                            />
                          </div>
                          <div className={styles.endText}>まで</div>
                        </div>
                        <div className={styles.formField}>
                          <SelectField
                            input={{
                              value: selectedSurveyForm,
                              onChange: value => {
                                setSelectedSurveyForm(value);
                              }
                            }}
                            label="調査フォーム"
                            name="SurveyForm"
                            simpleValue
                            required
                            clearable={false}
                            options={formOptions}
                          />
                        </div>
                        <div className={styles.mailField}>
                          <SelectField
                            input={{
                              value: selectedMail,
                              onChange: value => {
                                setSelectedMail(value);
                              }
                            }}
                            label="メール内容"
                            name="Mail"
                            simpleValue
                            required
                            clearable={false}
                            options={mailOptions}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className={styles.modalFooter}>
                        <Modal.Buttons>
                          <Button className={styles.cancelButton} onClick={hideModal}>
                            キャンセル
                          </Button>
                          <Button
                            className={styles.sendButton}
                            primary
                            {...taskRunningProps}
                            onClick={sendForm}
                            disabledReason={taskRunning ? 'ストレスチェック送信中のため送信できません' : null}
                            disabled={
                              !isFutureDate(surveyEndDate) ||
                              !selectedSurveyForm ||
                              !selectedMail ||
                              loading ||
                              taskRunning
                            }>
                            送信
                          </Button>
                        </Modal.Buttons>
                      </div>
                    </Modal.Footer>
                  </div>
                </Modal.Modal>
              </form>
            )}
          </>
        );
      }}
    </Modal>
  );
};

export const BulkRequestForm = reduxForm({
  form: 'BulkRequestForm',
  enableReinitialize: true
})(BulkRequest);
