import React from 'react';
import { autofill, getFormValues, getFormMeta } from 'redux-form';
import SortingFieldsWithResult from './SortingFieldsWithResult';
import styles from '../components/SearchForm.scss';
import { useSession, reduxForm, searchDetector } from '../components';
import useReactRouter from 'use-react-router';
import { saveDisplayEmployeeLimit, getSavedDisplayEmployeeLimit } from '../components/utils';
import { convertFormToQueryString, convertQueryToForm, getCurrentQueryFromLocation } from './utils';
import { compose } from 'lodash/fp';
import moment from 'moment';

export const SearchForm = ({ handleSubmit, totalCount, isLoading, buttons, statusField }) => {
  const {
    location: { pathname }
  } = useReactRouter();
  const { user } = useSession();

  return (
    <div className={styles.searchForm}>
      <form onSubmit={handleSubmit}>
        <div className={styles.mainSearchFields}>{statusField ? statusField(handleSubmit) : <div />}</div>
        <div className={styles.buttons}>{buttons}</div>
        <SortingFieldsWithResult
          limit={getSavedDisplayEmployeeLimit(pathname, user)}
          onChangeLimit={newLimit => {
            saveDisplayEmployeeLimit(pathname, user, newLimit);
            handleSubmit();
          }}
          onChangeSortType={handleSubmit}
          count={totalCount}
          sortName="sortType"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

const validate = values => {
  const errors = { age_to: '', age_from: '' };
  if (values.age_from && values.age_to && +values.age_from > +values.age_to) {
    errors.age_to = errors.age_from = 'の範囲が正しくありません';
  }
  ['joined_at', 'retired_at', 'birthday'].forEach(prefix => {
    if (
      [values[`${prefix}_start`], values[`${prefix}_end`]].every(
        date => date && moment(date, 'YYYY/MM/DD').isValid()
      ) &&
      moment(values[`${prefix}_start`], 'YYYY/MM/DD').diff(moment(values[`${prefix}_end`], 'YYYY/MM/DD'), 'days') > 0
    ) {
      errors[`${prefix}_start`] = errors[`${prefix}_end`] = 'の範囲が正しくありません';
    }
  });
  return errors;
};

export default formName =>
  compose(
    searchDetector({ convertFormToQueryString, convertQueryToForm, getCurrentQueryFromLocation }),
    reduxForm({
      form: formName,
      enableReinitialize: true,
      validate,
      onSubmit(_, dispatch, { handleSearch }) {
        dispatch((dispatch, getState) => {
          const meta: any = getFormMeta(formName)(getState());
          const autofilled: boolean = meta?.page?.autofilled;
          if (!autofilled) {
            dispatch(autofill(formName, 'page', 1));
          }
          handleSearch(getFormValues(formName)(getState()));
        });
      }
    })
  )(SearchForm);
