import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, LoadingPage, useSession, FormNameProvider, PaginatorWithResult } from '../components';
import { gql } from '@apollo/client';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import styles from './Roles.scss';
import { Strage, MinusCircle, Edit, Delete } from 'jbc-front/components/icons';
import { FilteringTag } from 'jbc-front/components/SearchForm';
import searchForm from '../users/SearchForm';
import { convertQueryToForm, convertFormToGraphQl, getCurrentQueryFromLocation } from '../users/utils';
import { AddUserForm as AddUser } from './AddUser';
import { find, map, filter, remove } from 'lodash';
import { compact } from 'lodash/fp';
import Select from 'react-select';
import ActionButton from 'jbc-front/components/ActionButton';
import { useNotify } from '../actions';

const USERS = gql`
  query users($limit: Int, $page: Int, $search: UserSearchInput) {
    client {
      users(limit: $limit, page: $page, search: $search) {
        totalCount
        list {
          id
          name
          email
          roleType
          employee {
            staffCode
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const CHANGE_ROLE = gql`
  mutation change_role($input: ChangeRoleInput!) {
    changeRole(input: $input) {
      client {
        id
      }
    }
  }
`;

const requestRoles = [
  { id: 'all', name: 'すべて' },
  { id: 'practitioner', name: '実施者' },
  { id: 'person_in_charge', name: '実務担当者' }
];

const roleTypeOptions = [
  { label: '実施者', value: 'practitioner' },
  { label: '実務担当者', value: 'person_in_charge' }
];
type RoleTypeOption = (typeof roleTypeOptions)[0];

const additionalParams = { embed: [] };

const searchFormName = 'userSearchTop';

const SearchForm = searchForm(searchFormName);

const SearchRoleField = ({ input: inputProps, roles, onRoleChange }) => {
  return (
    <div className={styles.searchRoleField}>
      {roles.map(item => (
        <FilteringTag
          key={item.id}
          selected={item.id === inputProps.value}
          onClick={() => {
            const newValue = item.id;
            inputProps.onChange(newValue);
            inputProps.onBlur(newValue);
            onRoleChange();
          }}>
          <div className={styles.role}>{item.name}</div>
        </FilteringTag>
      ))}
    </div>
  );
};

const customControlStyles = base => ({
  ...base,
  minHeight: '34px',
  height: '34px',
  display: 'flex',
  alignItems: 'center'
});

const CellWithLineThrough = ({ content, deleteFlag }) => (
  <td>{deleteFlag ? <div className={styles.withLineThrough}>{content}</div> : <div>{content}</div>}</td>
);

export const Roles = ({ location: { search, pathname } }) => {
  const notify = useNotify();
  const [editing, setEditing] = useState(false);
  const [roleTypeChanges, setRoleTypeChanges] = useState([] as any[]);
  const { user } = useSession();
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, user));
  const variables = convertFormToGraphQl(formValues);
  const { data, loading } = useQuery(USERS, { variables, fetchPolicy: 'no-cache' });
  const changeRole = useMutation(CHANGE_ROLE);
  const users: any[] = data?.client?.users?.list || [];
  useEffect(() => {
    setRoleTypeChanges(new Array<any>(users.length).fill(null));
  }, [data]);
  const saveAdmin = async () => {
    const changes = compact(roleTypeChanges);
    await changeRole.mutation({
      variables: {
        input: {
          userRoleChanges: compact(roleTypeChanges)
        }
      }
    });
    for (const change of changes) {
      find(users, { id: change.id }).roleType = change.roleType;
    }
    const deleteIds = map(
      filter(changes, change => change.delete),
      'id'
    );
    remove(users, user => deleteIds.includes(user.id));
    setRoleTypeChanges(new Array<any>(users.length).fill(null));
    notify('更新しました', 'success');
    setEditing(false);
  };
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">権限管理</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.main}>
            <div className={styles.addUser}>
              <div className="l-breadcrumb">
                <Link to="/settings" className="l-breadcrumb-link">
                  設定
                </Link>
                <span className="l-breadcrumb-here">権限管理設定</span>
              </div>
              <AddUser />
            </div>
            <SearchForm
              isLoading={loading}
              totalCount={data?.client?.users?.totalCount}
              additionalParams={additionalParams}
              statusField={handleSubmit => (
                <Field name="roleType" component={SearchRoleField} roles={requestRoles} onRoleChange={handleSubmit} />
              )}
              buttons={
                editing ? (
                  <div className={styles.buttonWrap}>
                    <ActionButton onClick={() => saveAdmin()} icon={<Strage size={15} />}>
                      保存
                    </ActionButton>
                    <ActionButton
                      className={styles.cancelButton}
                      onClick={() => {
                        setEditing(false);
                        setRoleTypeChanges(new Array<any>(users.length).fill(null));
                      }}
                      icon={<MinusCircle size={15} />}>
                      キャンセル
                    </ActionButton>
                  </div>
                ) : (
                  <div className={styles.editButton}>
                    <ActionButton onClick={() => setEditing(true)} icon={<Edit size={15} />}>
                      編集
                    </ActionButton>
                  </div>
                )
              }
            />
            {loading ? (
              <LoadingPage />
            ) : !data?.client ? (
              <div className="l-wrap-xs u-pt100">
                <div className="l-box-message">
                  <p className="m-title-sub u-pt0 u-ta-c">管理者を取得できませんでした。</p>
                </div>
              </div>
            ) : (
              <>
                <div className="l-overflow-scroll">
                  <table className={styles.table}>
                    <thead>
                      <tr className={styles.header}>
                        <th className={styles.roleTypeColumn}>権限</th>
                        <th className={styles.staffCodeColumn}>スタッフコード</th>
                        <th className={styles.nameColumn}>氏名</th>
                        {editing ? (
                          <>
                            <th className={styles.emailColumnEditing}>メールアドレス</th>
                            <th className={styles.deleteColumn} />
                          </>
                        ) : (
                          <th className={styles.emailColumn}>メールアドレス</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {users.length > 0 &&
                        users.map((user, i) => (
                          <tr key={user.email} className={styles.row}>
                            <td className={styles.roleCell}>
                              {editing ? (
                                roleTypeChanges[i]?.delete ? (
                                  <div className={styles.withLineThrough}>
                                    {
                                      find(roleTypeOptions, { value: roleTypeChanges[i]?.roleType || user.roleType })
                                        ?.label
                                    }
                                  </div>
                                ) : (
                                  <Select
                                    name={user.id}
                                    className={styles.select}
                                    value={find<RoleTypeOption>(roleTypeOptions, {
                                      value: roleTypeChanges[i]?.roleType || user.roleType
                                    })}
                                    onChange={(v: any) => {
                                      const newRoleTypes = [...roleTypeChanges];
                                      if (v !== user.roleType) {
                                        newRoleTypes[i] = {
                                          id: user.id,
                                          roleType: v,
                                          delete: false
                                        };
                                      } else {
                                        newRoleTypes[i] = null;
                                      }
                                      setRoleTypeChanges(newRoleTypes);
                                    }}
                                    options={roleTypeOptions}
                                    simpleValue
                                    clearable={false}
                                    styles={{ control: customControlStyles }}
                                  />
                                )
                              ) : (
                                <div>{find(requestRoles, { id: user.roleType })?.name}</div>
                              )}
                            </td>
                            <CellWithLineThrough
                              content={user.employee ? user.employee.staffCode || '-' : '従業員登録なし'}
                              deleteFlag={roleTypeChanges[i]?.delete}
                            />
                            <CellWithLineThrough
                              content={
                                user.employee
                                  ? `${user.employee.lastName} ${user.employee.firstName}`
                                  : user.name || '-'
                              }
                              deleteFlag={roleTypeChanges[i]?.delete}
                            />
                            <CellWithLineThrough content={user.email} deleteFlag={roleTypeChanges[i]?.delete} />
                            <td>
                              {editing ? (
                                <div
                                  className={roleTypeChanges[i]?.delete ? styles.disabled : undefined}
                                  onClick={
                                    roleTypeChanges[i]?.delete
                                      ? undefined
                                      : () => {
                                          const newRoleTypes = [...roleTypeChanges];
                                          newRoleTypes[i] = {
                                            id: user.id,
                                            roleType: roleTypeChanges[i]?.roleType || user.roleType,
                                            delete: true
                                          };
                                          setRoleTypeChanges(newRoleTypes);
                                        }
                                  }>
                                  <span className={styles.icon}>
                                    <Delete />
                                  </span>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <FormNameProvider formName={searchFormName}>
                  <PaginatorWithResult count={data?.client?.users?.totalCount} isLoading={loading} />
                </FormNameProvider>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
