import { range, some } from 'lodash';
import { toArray, reverse, chunk } from 'lodash/fp';
import { toJpYearDate } from 'jbc-front/utils/jpYear';
import moment from 'moment';
import { pipe } from './pipe';

export const recordDisplay: (text?: string) => string = text => (text ? text : '―');

export const numberWithSplit = num => {
  const numStr = `${num ?? recordDisplay()}`;
  if (numStr.startsWith('-')) {
    return '-' + numberWithSplit(numStr.substr(1));
  }
  return pipe(
    toArray,
    reverse,
    chunk(3),
    reverse
  )(numStr)
    .map(arr => arr.reverse().join(''))
    .join(',');
};

export const recordDisplayYen = record => {
  if (!record) {
    return recordDisplay();
  }
  return numberWithSplit(record) + '円';
};

export const recordDisplayDate = (date, format = 'wareki') => {
  if (!date) {
    return recordDisplay();
  }
  if (format === 'wareki') {
    return toJpYearDate(date);
  }
  if (format === 'seireki') {
    return moment(date).format('LL');
  }
  return moment(date).format(format);
};

export const recordDisplayNumbers = (record, name, length, split = '-') => {
  if (!record) {
    return recordDisplay();
  }
  const fields = range(0, length).map(idx => record[`${name}${idx}`]);
  if (some(fields, field => !field)) {
    return recordDisplay();
  }
  return fields.join(split);
};

export default recordDisplay;
