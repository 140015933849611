import React from 'react';
import { Notifications } from './components/Notifications';
import classnames from 'classnames';
import { Footer } from './components/Footer';
import { Sidebar } from './components/Sidebar';
import { Header } from './components/Header';
import styles from './Layout.scss';

const Layout = ({ children, sidebar, showSession }) => (
  <div>
    <Notifications />
    {showSession ? <Header showNavi /> : <Header showNavi={false} />}
    <div className={styles.main}>
      {sidebar && <Sidebar />}
      <div className={classnames({ [styles.marginSidebar]: sidebar })}>
        <div className="l-main-contents">{children}</div>
        <Footer />
      </div>
    </div>
  </div>
);

export default Layout;
