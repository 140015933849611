import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import routerScroll from './routerScroll';
import rootReducer, { RootState } from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import epics from './epics';
import trimFields from './trimFields';

const isDevelopmentEnv = process.env.NODE_ENV.startsWith('development');

const epicMiddleware = createEpicMiddleware();
export const history = createHistory();
const defaultMiddleware = [thunk, routerMiddleware(history), routerScroll, epicMiddleware, trimFields];

export default function configureStore(initialState?: Partial<RootState>, middleware: any[] = []) {
  const composeEnhancers = (isDevelopmentEnv && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const reducer = rootReducer(history);

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...defaultMiddleware, ...middleware))
  );

  epicMiddleware.run(epics);

  if (module.hot) {
    module.hot.accept('./reducers', async () => {
      const { default: nextRootReducer } = await import('./reducers');
      store.replaceReducer(nextRootReducer(history));
    });
  }

  return store;
}
