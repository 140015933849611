import { isString } from 'lodash';
import { Middleware } from 'redux';

const trimFields: Middleware = () => next => action => {
  if (action && action.type === '@@redux-form/BLUR' && isString(action.payload)) {
    next({ ...action, payload: action.payload.trim() || null });
  } else {
    next(action);
  }
};

export default trimFields;
