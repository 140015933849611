import React, { useEffect, useState } from 'react';
import { reduce, unset, set, take } from 'lodash/fp';
import styles from './SelectList.scss';
import { useNotify } from '../hooks/useNotify';
import { pipe } from './utils';

const Hint = ({ onClick, children }) => {
  useEffect(() => {
    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, [onClick]);
  return (
    <div className={styles.hint}>
      <div className={styles.hintShape} />
      <div className={styles.hintMessage}>{children}</div>
    </div>
  );
};

export const Td = ({ onClick, selected, disabledMessage }) => {
  const [showHint, setShowHint] = useState(false);
  function hideHint() {
    setShowHint(false);
  }
  const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
  const disabledClass = disabledMessage ? ' u-cur-notallowed' : ' u-cur-pointer';
  return (
    <td
      onClickCapture={e => {
        e.stopPropagation();
        disabledMessage ? setShowHint(true) : onClick();
      }}
      className={selectedClass + disabledClass}>
      {showHint && <Hint onClick={hideHint}>{disabledMessage}</Hint>}
      <input type="checkbox" checked={selected} className="u-cur-pointer" readOnly />
    </td>
  );
};

export const Th = ({ onClick, selected }) => {
  const allClickClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
  return (
    <th onClick={onClick} className={allClickClass + ' m-table-list-check u-cur-pointer'}>
      <input type="checkbox" readOnly checked={selected} />
    </th>
  );
};

export const SelectList = ({
  mode,
  disabledMessage,
  shouldSelectByAll = item => !disabledMessage(item),
  list,
  children,
  selectedInfo,
  maxCount,
  overMaxCountMessage = ''
}) => {
  const [selected, setSelected] = useState({});
  const notify = useNotify();
  const toggle = item => {
    console.log('toggled!');
    const selectedCount = reduce((sum, value) => (value ? sum + 1 : sum), 0, selected);
    if (selected[item.id]) {
      setSelected(unset(item.id, selected));
    } else if (selectedCount >= maxCount) {
      notify(overMaxCountMessage);
    } else {
      setSelected(set(item.id, selectedInfo(item), selected));
    }
  };

  const allSelected = () =>
    list.some(item => selected[item.id]) && list.every(item => !shouldSelectByAll(item) || selected[item.id]);

  const getSelectedInfo = list => list.map(item => ({ [item.id]: selectedInfo(item) }));

  const selectAll = () => {
    if (allSelected()) {
      setSelected(Object.assign({}, selected, ...list.map(item => ({ [item.id]: false }))));
    } else {
      const selectedCount = reduce((sum, value) => (value ? sum + 1 : sum), 0, selected);
      const added = list.filter(item => shouldSelectByAll(item) && !selected[item.id]);
      if (selectedCount + added.length > maxCount) {
        notify(overMaxCountMessage, 'error');
      }
      if (selectedCount < maxCount) {
        setSelected(Object.assign({}, selected, ...pipe(take(maxCount - selectedCount), getSelectedInfo)(added)));
      }
    }
  };

  const reset = () => {
    setSelected({});
  };

  useEffect(() => {
    reset();
  }, [mode]);

  return children({
    list: list.map(item => ({
      item,
      selected: !!selected[item.id],
      toggle: () => toggle(item),
      td: <Td disabledMessage={disabledMessage(item)} selected={!!selected[item.id]} onClick={() => toggle(item)} />
    })),
    selected,
    reset,
    selectAll,
    th: <Th selected={allSelected()} onClick={selectAll} />
  });
};

SelectList.defaultProps = {
  disabledMessage: () => null,
  selectedInfo: () => true,
  maxCount: Infinity
};
