import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pulldown } from 'jbc-front/components/icons';
import styles from './Settings.scss';

const Item = ({ title, text, to, disabled = false }) => {
  const [showHint, setShowHint] = useState(false);
  const renderChildren = () => (
    <>
      {showHint && <Hint onClick={() => setShowHint(false)}>近日リリース予定の機能です</Hint>}
      <dl className={styles.definition}>
        <dt className={styles.dt}>
          <span className={styles.icon}>
            <Pulldown size={13} transform="rotate(-90)" />
          </span>
          {title}
        </dt>
        <dd className={styles.text}>{text}</dd>
      </dl>
    </>
  );
  return (
    <li className={disabled ? styles.liDisabled : styles.li}>
      {disabled ? (
        <div
          className={styles.itemDisabled}
          onClickCapture={e => {
            e.stopPropagation();
            setShowHint(true);
          }}>
          {renderChildren()}
        </div>
      ) : (
        <Link to={to} className={styles.itemWrap}>
          {renderChildren()}
        </Link>
      )}
    </li>
  );
};

const Hint = ({ onClick, children }) => {
  useEffect(() => {
    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, [onClick]);
  return (
    <div className={styles.hint}>
      <div className={styles.hintShape} />
      <div className={styles.hintMessage}>{children}</div>
    </div>
  );
};

export const Settings = () => {
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">設定</h1>
      </div>
      <div className="l-wrap-l l-contents-wrap">
        <div className={styles.main}>
          <section className={styles.section}>
            <ul className={styles.list}>
              <Item to="/settings/survey_forms" title="調査フォーム" text="依頼する調査フォームの内容を確認できます" />
              <Item to="/settings/mails" title="メールカスタマイズ" text="依頼メールの内容をカスタマイズできます" />
              <Item to="/settings/users" title="権限管理" text="権限の変更、新規管理者の追加ができます" />
              <Item
                to="/settings/implementation_surveys"
                title="実施履歴"
                text="実施したストレスチェックの確認、削除ができます"
              />
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};
