import React from 'react';
import { AsyncTaskError, ListItem, ListGroup, useQuery, LoadingPage, getTitle } from '../components';
import { Survey, initialSurvey } from '../entity';
import { gql } from '@apollo/client';
import styles from './Dashboard.scss';
import { StressCheck, StressCheckGroupResult } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import { FilteringTag } from 'jbc-front/components/SearchForm';
import { Link } from 'react-router-dom';
import _Select from 'react-select';
import useReactRouter from 'use-react-router';
import moment from 'moment';
import { Panel } from '../components/PrimaryPanel';
import { BulkResendForm as BulkResend } from './BulkResend';
import { ChangeDeadlineForm as ChangeDeadline } from './ChangeDeadline';
import { find } from 'lodash';
import useCallbackAfterTask from '../hooks/useCallbackAfterTask';

const Select = _Select as any;

const customStyles = {
  container: provided => ({
    ...provided,
    width: '560px',
    fontSize: '13px'
  }),
  control: provided => ({
    ...provided,
    boxShadow: null,
    height: '40px',
    border: 'solid 1px #dddddd',
    position: 'relative'
  }),
  singleValue: provided => ({
    ...provided,
    position: 'absolute',
    top: '50%'
  })
};

const IMPLEMENTATION_SURVEYS = gql`
  query surveys {
    client {
      implementationSurveys {
        id
        createdAt
        surveyEndDate
        surveyForm {
          title
        }
        employeeCount
        answeredCount
        notableCount
      }
    }
  }
`;

const implementStatuses = [
  { id: 'unfinishedSurveys', name: '実施中' },
  { id: 'finishedSurveys', name: '実施済み' }
];

function filterSurveysByFinishedOrNot(surveyQueryResult, condition) {
  return surveyQueryResult.data.client.implementationSurveys.filter(survey =>
    condition(
      new Date(moment(survey.surveyEndDate).endOf('days').format()),
      survey.employeeCount === survey.answeredCount
    )
  );
}

const noOptionsMessage = displaySurvey => {
  switch (displaySurvey) {
    case 'unfinishedSurveys':
      return '実施中のストレスチェックがありません';
    case 'finishedSurveys':
      return '実施済みのストレスチェックがありません';
    default:
      return 'No options';
  }
};

const SurveySummary = ({ survey }) => {
  if (!survey) {
    return <div>実施履歴が選択されていません</div>;
  }
  const employeeCount = survey.employeeCount;
  const unfinishedEmployeeCount = employeeCount - survey.answeredCount;
  const finishedEmployeeCount = survey.answeredCount;
  const notableEmployeeCount = survey.notableCount;
  return (
    <>
      <div className={styles.surveyPeriod}>
        <p>
          実施期間：
          {moment(survey.createdAt).format('YYYY/MM/DD')} から {moment(survey.surveyEndDate).format('YYYY/MM/DD')} まで
        </p>
      </div>
      <div className={styles.summaryTable}>
        <div className={styles.col}>
          <div className={styles.colTitle}>全体</div>
          <div className={styles.colCount}>{employeeCount}人</div>
        </div>
        <div className={styles.col}>
          <div className={styles.colTitle}>未回答</div>
          <div className={styles.colCount}>
            {unfinishedEmployeeCount}人 (
            {employeeCount === 0 ? '-' : ((unfinishedEmployeeCount / employeeCount) * 100).toFixed(0)}%)
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.colTitle}>回答済み</div>
          <div className={styles.colCount}>
            {finishedEmployeeCount}人 (
            {employeeCount === 0 ? '-' : 100 - parseInt(((unfinishedEmployeeCount / employeeCount) * 100).toFixed(0))}%)
          </div>
        </div>
        <div className={styles.colRight}>
          <div className={styles.colTitle}>うち高ストレス者</div>
          <div className={styles.colCount}>
            {notableEmployeeCount}人 (
            {employeeCount === 0 ? '-' : ((notableEmployeeCount / employeeCount) * 100).toFixed(0)}%)
          </div>
        </div>
      </div>
    </>
  );
};

export const Dashboard = ({
  match: {
    params: { displaySurvey, surveyId }
  }
}) => {
  const { history } = useReactRouter();
  const surveyQueryResult = useQuery(IMPLEMENTATION_SURVEYS, { fetchPolicy: 'no-cache' });
  const refetchSurveys = () => {
    surveyQueryResult.refetch();
  };

  let surveys: Survey[] = [];
  let selectedSurvey = initialSurvey;
  if (surveyQueryResult.data?.client) {
    if (displaySurvey === 'finishedSurveys') {
      surveys = filterSurveysByFinishedOrNot(
        surveyQueryResult,
        (date, allEmployeeFinished) => date > new Date() && !allEmployeeFinished
      );
      surveys = filterSurveysByFinishedOrNot(
        surveyQueryResult,
        (date, allEmployeeFinished) => date <= new Date() || allEmployeeFinished
      );
    } else {
      displaySurvey = 'unfinishedSurveys';
      surveys = filterSurveysByFinishedOrNot(
        surveyQueryResult,
        (date, allEmployeeFinished) => date > new Date() && !allEmployeeFinished
      );
    }
    if (surveyId) {
      const pickedSurvey: any = find(surveys, { id: surveyId });
      if (pickedSurvey) {
        selectedSurvey = pickedSurvey;
      } else {
        selectedSurvey = surveys[0];
      }
    } else {
      selectedSurvey = surveys[0];
    }
  }

  useCallbackAfterTask(['send_request'], [surveyQueryResult.refetch]);

  const disabled = surveys.length === 0;
  if (surveyQueryResult.loading) {
    return <LoadingPage />;
  }
  const surveyOptions = surveys.map(survey => {
    return {
      label: getTitle(survey),
      value: survey
    };
  });

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">ストレスチェック</h1>
      </div>
      <div className={styles.contentsWrap}>
        <div className={styles.wrap}>
          <AsyncTaskError />
          <ListGroup>
            <ListItem as={Link} to="/employees">
              <div className={styles.largeButton}>
                <StressCheck size={32.1} color={'#3498db'} />
                <p className={styles.name}>ストレスチェックを実施する</p>
              </div>
            </ListItem>
          </ListGroup>
          <div className={styles.implementationCondition}>実施状況</div>
          <div className={styles.selectType}>
            <div className={styles.searchImplementStatusField}>
              {implementStatuses.map(item => (
                <FilteringTag
                  key={item.id}
                  selected={item.id === displaySurvey}
                  onClick={() => {
                    history.push(`/dashboard/${item.id}`);
                  }}>
                  <div className={styles.filterButton}>{item.name}</div>
                </FilteringTag>
              ))}
              <ChangeDeadline
                key={selectedSurvey?.id}
                selectedSurvey={selectedSurvey}
                disabled={disabled}
                targetCount={selectedSurvey ? selectedSurvey.employeeCount - selectedSurvey.answeredCount : 0}
                onUpdated={refetchSurveys}
                displayType={displaySurvey}
              />
            </div>
          </div>
          <div className={styles.selectForm}>
            <Select
              styles={customStyles}
              name="SurveyForm"
              className={styles.select}
              simpleValue
              required
              clearable={false}
              value={{ label: getTitle(selectedSurvey), value: selectedSurvey }}
              options={surveyOptions}
              noResultsText={noOptionsMessage(displaySurvey)}
              onChange={(value: any) => {
                history.push(`/dashboard/${displaySurvey}/${value.id}`);
              }}
            />
            {displaySurvey === 'unfinishedSurveys' ? (
              <BulkResend
                history={history}
                selectedSurvey={selectedSurvey}
                disabled={disabled}
                targetCount={selectedSurvey ? selectedSurvey.employeeCount - selectedSurvey.answeredCount : 0}
              />
            ) : (
              <div className={styles.results}>
                <div className={styles.personalResultButton}>
                  <Button
                    className={[styles.button, !disabled && styles.ableButton].join(' ')}
                    as={disabled ? Button : Link}
                    to={`/personal_results/${selectedSurvey?.id}`}
                    icon={<StressCheck className={styles.icon} />}
                    widthWide
                    disabled={disabled}>
                    個人結果を見る
                  </Button>
                </div>
                <div className={styles.groupResultButton}>
                  <Button
                    className={[styles.button, !disabled && styles.ableButton].join(' ')}
                    as={disabled ? Button : Link}
                    to={`/group_result/${selectedSurvey?.id}`}
                    icon={<StressCheckGroupResult className={styles.icon} />}
                    widthWide
                    disabled={disabled}>
                    集団分析を見る
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={styles.surveySummary}>
            <Panel>
              {surveys.length > 0 ? (
                <SurveySummary survey={selectedSurvey} />
              ) : (
                <div className={styles.noSurvey}>
                  <p>実施履歴がありません</p>
                </div>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
};
