import { camelCase, snakeCase, isEmpty, isEqual, pick } from 'lodash';
import { identity, pickBy } from 'lodash/fp';
import queryString from 'query-string';
import { formValueSelector, getFormValues, autofill as autofillForm } from 'redux-form';
import { getSavedDisplayEmployeeLimit, pipe } from '../components/utils';

export const formName = 'employeeSearchForm';
export const selector = formValueSelector(formName);
export const getValues = getFormValues(formName);
export const autofill = autofillForm.bind(null, formName);
export const defaultQuery = {
  status: 'all',
  _page: '1',
  sort_type: 'staff_code__asc'
};

export const convertQueryToForm = query => {
  return Object.assign(
    { sortType: `${query.sort}__${query.order}` },
    ...Object.entries(query)
      .map(([k, v]) => [k[0] === '_' ? `_${camelCase(k)}` : camelCase(k), v])
      .map(([k, v]) => convertQueryToFormField(k, v))
      .filter(identity)
  );
};

const convertQueryToFormField = (fieldName, value) => {
  switch (fieldName) {
    case 'officeIds':
      return { offices: value };
    case 'employmentTypeIds':
      return { employment_types: value };
    case '_page':
      return { page: value };
    case 'sort':
    case 'order':
      return undefined;
    default:
      return { [fieldName]: value };
  }
};

export const convertFormToQueryString = data =>
  queryString.stringify(convertFormToQuery(data), { arrayFormat: 'bracket' });

const convertFormToQuery = data => {
  if (data === undefined) return {};
  return Object.assign(
    {},
    ...Object.entries(data)
      .map(([k, v]) => [k[0] === '_' ? `_${snakeCase(k)}` : snakeCase(k), v])
      .map(([k, v]) => convertFormToQueryField(k, v))
      .filter(identity)
  );
};

const convertFormToQueryField = (fieldName, value) => {
  switch (fieldName) {
    case 'offices':
      return !isEmpty(value) ? { office_ids: value } : null;
    case 'employmentTypes':
      return !isEmpty(value) ? { employment_type_ids: value } : null;
    case 'limit':
      return null;
    case 'page':
      return { _page: value };
    case 'sortType': {
      const [sort, order] = value.split('__');
      return { order, sort };
    }
    default:
      return { [fieldName]: value };
  }
};

export const getCurrentQueryFromLocation = (location, user) => {
  const query = {
    ...defaultQuery,
    ...queryString.parse(location.search, { arrayFormat: 'bracket' }),
    limit: getSavedDisplayEmployeeLimit(location.pathname, user)
  };
  return query;
};

export const hasConditions = formValues => {
  return !isEqual(pipe(convertFormToQuery, pickBy(identity))(formValues), defaultQuery);
};

const filtersToGraphQl = (obj, keys) =>
  Object.assign(
    {},
    ...keys.map(key => ({
      [key]: obj[key] && obj[key].map(v => (v === 'true' ? true : v === 'false' ? false : v))
    }))
  );

export const convertFormToGraphQl = formValues => {
  const [sortColumn, sortOrder] = (formValues.sortType || convertQueryToForm(defaultQuery).sortType).split('__');
  return {
    page: +formValues.page || 1,
    limit: +formValues.limit || 100,
    search: {
      ...(formValues.status !== 'all' && pick(formValues, 'status')),
      ...pick(formValues, 'q'),
      sortColumn,
      sortOrder,
      ...filtersToGraphQl(formValues, ['offices', 'employmentTypes', 'groups', 'positions'])
    }
  };
};
