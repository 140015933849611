import React from 'react';
import { useMutation } from '.';
import { gql } from '@apollo/client';
import Select from 'react-select';
import { find } from 'lodash';
import styles from './SelectLanguage.scss';

const ChangeLanguage = gql`
  mutation changeLanguage($input: ChangeLanguageInput!) {
    changeLanguage(input: $input) {
      employee {
        id
        language
      }
    }
  }
`;

export const languageOptions = [
  { label: '日本語', value: 'japanese' },
  { label: 'English', value: 'english' }
];

interface SelectLanguageProps {
  employee: {
    id: string;
    language?: string;
  };
  className?: string | null;
}

export const SelectLanguage: React.FC<SelectLanguageProps> = ({ employee, className = null }) => {
  const { mutation: changeLanguage } = useMutation(ChangeLanguage);
  const language = employee.language || 'japanese';
  const selectValue = {
    label: find(languageOptions, { value: language })?.label ?? '日本語',
    value: language
  };

  return (
    <Select
      name="SelectLanguage"
      className={className || styles.select}
      simpleValue
      required
      clearable={false}
      value={selectValue}
      options={languageOptions}
      onChange={async (selected: any) => {
        await changeLanguage({
          variables: {
            input: {
              employeeId: parseInt(employee.id),
              language: selected
            }
          }
        });
      }}
    />
  );
};
